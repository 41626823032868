<template>
  <div id="usersListComponent">
    <div class="jumbot">
      <div style="margin-bottom: 0px" class="field has-addons">
        <div class="control is-expanded">
          <div class="select is-fullwidth">
            <select v-model="selectedUser">
              <option value="">Select an option from the list</option>
              <option
                v-for="(user, index) in availableUsers"
                :key="index"
                :value="user._id"
              >
                {{ user.username }}
              </option>
            </select>
          </div>
        </div>
        <div class="control">
          <button
            type="button"
            @click="AddUser()"
            class="button is-primary"
            :disabled="userIsSelected"
          >
            Add
          </button>
        </div>
      </div>
      <br />
      <div class="field">
        <div class="userListBox">
          <ul>
            <li v-for="(userId, index) in selectedUsers" :key="index">
              <span>{{ noName(userId) }}</span>
              <span class="icon is-small is-left" @click="deleteUser(userId)">
                <icon :icon="['fas', 'trash-alt']" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'UsersList',
  props: {
    currentCompany: {
      type: Object,
      required: true
    },
    currentUsers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedUser: '',
      selectedUsers: JSON.parse(JSON.stringify(this.currentUsers)),
      selectedCompany: this.currentCompany,
      users: [],
      firstUsersResponse: null,
    };
  },
  created() {
    this.fillUsers();
  },
  computed: {
    userIsSelected() {
      return this.selectedUser ? false : true;
    },
    availableUsers() {
      // `this` points to the vm instance
      return this.users.filter(user => {
        return this.selectedUsers.indexOf(user._id) === -1;
      }) || [];
    }
  },
  watch: {
    selectedUsers: function (newVal, oldVal) {
      this.$emit('selected:users:changed', newVal);
    },
    currentUsers: function (newVal, oldVal) {
      // if arrays are different update the value of the local array.
      this.selectedUsers = !arrayEquals(this.selectedUsers, newVal)
        ? JSON.parse(JSON.stringify(newVal))
        : this.selectedUsers;
      // function used to check if arrays are different.
      function arrayEquals(a, b) {
        return (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index])
        );
      }
    },
    currentCompany: function (newVal, oldVal) {
      if (this.selectedCompany._id !== newVal._id) {
        this.selectedCompany = newVal;
        this.fillUsers();
      }
    }
  },
  methods: {
    noName(id) {
      let name = this.users.find(element => {
        return element._id === id;
      });
      return name !== undefined ? name.username : 'User Not Found';
    },
    AddUser() {
      let userAdded = this.selectedUser;
      if (!this.selectedUsers.includes(userAdded)) {
        this.selectedUsers.push(userAdded);
      }
    },
    deleteUser(userRemoved) {
      let indexOfId = this.selectedUsers.indexOf(userRemoved);
      if (indexOfId !== -1) {
        this.selectedUsers.splice(indexOfId, 1);
      }
    },
    async fillUsers() {
      // try to get all the users from the db.
      const usersResponse = this.firstUsersResponse || await axios.get($formsConfig.core.api.users);
      // store the response in the component
      this.firstUsersResponse = usersResponse;
      // now complete missing info for the users users
      this.users = usersResponse.data;
      this.users.forEach(user => {
        if (!user.hasOwnProperty('company') || !user.company) {
          user.company = {
            name: 'N/A',
          };
        }
      });
      // finally filter them accordingly to the selected company.
      this.users = this.users.filter((user) => {
        return user.company._id === this.selectedCompany._id;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#usersListComponent {
  .userListBox {
    width: 100%;
    height: 9.5rem;
    padding: 0.5rem 1rem;
    overflow-y: scroll;
    background-color: $background;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        span:nth-child(1) {
          display: inline-block;
          width: 90%;
          padding: 0.3rem 0;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
        span:nth-child(2) {
          position: relative;
          top: 9px;
          float: right;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
