<template>
  <Layout>
    <div class="container">
      <div id="loginForm" class="container is-fluid">
        <h1>Welcome to Stonehurst Forms</h1>
        <div class="notification is-danger is-vcentered" v-if="generalError">
          {{ generalError }}
        </div>
        <form
          id="registrationForm"
          class="mainFormContainer"
          @submit="submitForm($event)"
          novalidate
        >
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="email">Email Address</label>
              <div class="control">
                <input
                  id="email"
                  name="email"
                  class="input"
                  :class="{ 'is-danger': !email.valid }"
                  type="email"
                  v-model="user.email"
                  placeholder="john.doe@company.com"
                  required="required"
                  @keyup="emitChange($event)"
                />
              </div>
              <p class="help" :class="{ 'is-danger': !email.valid }">
                {{ email.errorMessage }}
              </p>
            </div>
          </div>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="password">
                Password
                <span
                  class="icon right tooltip is-tooltip-left is-tooltip-multiline"
                  data-tooltip="The password must be between 8 and 15 characters and contain lower case, cappital letters, numbers and #, @ or * "
                >
                  <icon :icon="['fas', 'question-circle']" />
                </span>
              </label>
              <div class="control has-icons-right">
                <input
                  id="password"
                  name="password"
                  class="input"
                  :class="{ 'is-danger': !password.valid }"
                  type="password"
                  v-model="user.password"
                  placeholder="Password"
                  required="required"
                  :pattern="passwordPattern"
                  @keyup="emitChange($event)"
                />
                <span class="icon is-medium is-right">
                  <icon :icon="['fas', 'lock']" />
                </span>
              </div>
              <p class="help" :class="{ 'is-danger': !password.valid }">
                {{ password.errorMessage }}
              </p>
              <router-link :to="{ name: 'forgotPassword' }" class="link"
                >Forgot your password?</router-link
              >
            </div>
          </div>
          <button type="submit" class="button is-fullwidth is-primary">
            Log in
          </button>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'home',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  props: {},
  data: function() {
    return {
      generalError: '',
      passwordPattern: '^[a-zA-Z0-9@*#]{8,15}$',
      user: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      email: {
        valid: true,
        errorMessage: ''
      },
      password: {
        valid: true,
        errorMessage: ''
      }
    };
  },
  methods: {
    /**
     * This function performs the data validation and the
     * data submission if the form is valid.
     * @param {Event} evt The event will be used to cancel the
     * submission in case the form is invalid.
     * @returns boolean
     */
    submitForm(evt) {
      // Cancel the default action for the form as we will send an XHR request.
      evt.preventDefault();
      let form = this.$el.querySelector('#registrationForm');
      let fields = [
        {
          field: this.$el.querySelector('#email'),
          validation: this.email
        },
        {
          field: this.$el.querySelector('#password'),
          validation: this.password
        }
      ];
      // Check if the whole form is valid.
      if (form.checkValidity()) {
        let user = {
          email: this.user.email,
          username: this.user.email,
          password: this.user.password
        };
        axios
          .post($formsConfig.core.api.login, user)
          .then(response => {
            this.$cookieHandler.set(
              $formsConfig.core.cookieName,
              response.data.user
            );
            this.$router.push(
              this.$route.query.from ? this.$route.query.from : '/forms'
            );
          })
          .catch(err => {
            // TODO: add functionality to notify the user of the error.
            console.error(err);
            this.generalError = err.response.data.message;
          });
        return true;
      } else {
        // If the form is invalid, iterate over the fields to mark the ones
        // that need attention.
        fields.forEach(function(fieldObject) {
          fieldObject.validation.valid = fieldObject.field.checkValidity();
          fieldObject.validation.errorMessage =
            fieldObject.field.validationMessage;
        });
      }
    },
    validate(fieldObject) {
      let valid = fieldObject.field.checkValidity();
      fieldObject.validation.valid = valid;
      fieldObject.validation.errorMessage = fieldObject.field.validationMessage;
      return valid;
    },
    clearValidity(fieldObject) {
      fieldObject.validation.valid = true;
      fieldObject.validation.errorMessage = '';
    },
    emitChange(evt) {
      let field = evt.currentTarget;
      let fieldObject = {
        field: field,
        validation: this[field.attributes.name.value]
      };
      if (!this.validate(fieldObject)) {
        return;
      }
      this.clearValidity(fieldObject);
    }
  }
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  @extend %fieldContainer;
}

.is-fluid {
  align-items: center;
  background: $background-panels;
  border-radius: $control-radius;
  h1 {
    margin-bottom: 30px;
    text-align: center;
  }
}

#loginForm {
  padding: 50px 250px 50px 250px;
  margin: 20px 98px 0 92px;
}

.link {
  color: $color-link-text;
  text-align: right;
}
</style>
