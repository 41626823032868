import Vue from 'vue';

const requireComponent = require.context('.', false, /\.js$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  fileName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
  if (fileName === 'globals') {
    return;
  }
  Vue.directive(fileName, componentConfig.default || componentConfig);
});
