import Vue from 'vue';
import app from './App.vue';
import router from './router';
import './components/globals/globals';
import './directives/globals/globals';
import 'forms-commons';
import axios from 'axios';
import cookieHandler from './cookieHandler';
import Notification from 'vue-notification';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faChartBar,
  faEye,
  faTrashAlt,
  faPencilAlt,
  faUpload,
  faDownload,
  faSpinner,
  faFileCsv,
  faFilePdf,
  faChevronLeft,
  faMinusCircle,
  faPlusCircle,
  faPlus,
  faLock,
  faEdit,
  faSearch,
  faHistory,
  faFileImport,
  faAngleDown,
  faAngleUp,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//Adding fontawesome icons
library.add(
  faQuestionCircle,
  faChartBar,
  faEye,
  faTrashAlt,
  faPencilAlt,
  faUpload,
  faDownload,
  faSpinner,
  faFileCsv,
  faFilePdf,
  faChevronLeft,
  faMinusCircle,
  faPlusCircle,
  faPlus,
  faLock,
  faEdit,
  faSearch,
  faHistory,
  faFileImport,
  faAngleDown,
  faAngleUp,
  faCopy
);
Vue.component('icon', FontAwesomeIcon);

//Global CSS
import '@/design/site.scss';

Vue.config.productionTip = false;
Vue.use(require('vue-cookies'));
Vue.use(Notification);
Vue.prototype.$cookieHandler = cookieHandler;
$cookies.config('1d', window.location.host);

// Add a request interceptor
axios.interceptors.request.use(function(request) {
  Forms.$notify({
    group: 'loading',
    clean: true
  });
  // Show a loader notification.
  Forms.$notify({
    group: 'loading',
    title: 'Loading...',
    text: 'Please wait wihle your request is being processed.',
    classes: 'is-info',
    duration: -1,
    closeOnClick: false,
    ignoreDuplicates: true
  });
  return request;
});

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    Forms.$notify({
      group: 'loading',
      clean: true
    });
    return response;
  },
  function(error) {
    Forms.$notify({
      group: 'loading',
      clean: true
    });
    if (error.response.status === 401) {
      cookieHandler.remove($formsConfig.core.cookieName);
      window.location = `/login?from=${window.location.pathname}`;
    }
    return Promise.reject(error);
  }
);

const Forms = new Vue({
  router,
  render: h => h(app)
}).$mount('#app');
