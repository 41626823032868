<template>
  <transition
    name="modal"
    enter-class="modalEnter"
    leave-active-class="modalLeaveActive"
  >
    <div class="modal is-active modalContainer">
      <div class="modal-background" @click.self="closeModal(false)"></div>
      <div class="modal-card modalBody" :class="{'overflow': overflow}">
        <slot name="content"></slot>
        <template v-if="!$slots.content">
          <header class="modal-card-head modalHeader">
            <p class="modal-card-title">{{ titleText }}</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeModal(false)"
            ></button>
          </header>
          <section class="modal-card-body">
            <p>{{ bodyText }}</p>
          </section>
          <footer class="modal-card-foot">
            <button
              :class="'button ' + buttons.confirm.class"
              @click="closeModal(true)"
            >
              {{ buttons.confirm.text }}
            </button>
            <button
              v-if="buttons.reject"
              :class="'button ' + buttons.reject.class"
              @click="closeModal(false)"
            >
              {{ buttons.reject.text }}
            </button>
          </footer>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    bodyText: {
      type: String,
      default: 'Are you sure you want to remove this user?'
    },
    titleText: {
      type: String,
      default: 'Remove Confirmation'
    },
    buttons: {
      type: Object,
      default: function() {
        return {
          confirm: {
            text: 'Ok',
            class: 'is-primary'
          },
          reject: null
        };
      }
    },
    overflow: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    closeModal(answer) {
      this.$emit('closeModal', {
        remove: answer
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modalContainer {
  transition: all 0.3s ease;
  .modalBody {
    transition: all 0.3s ease;
    .modalHeader {
      p {
        margin: 0;
      }
    }
  }
  .overflow {
    overflow: auto !important;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
**/

.modalEnter {
  opacity: 0;
  transition: all 0.3s ease;
}

.modalLeaveActive {
  opacity: 0;
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.modalEnter .modalBody,
.modalLeaveActive .modalBody {
  transform: scale(1.5);
}

</style>
