import home from './views/Home.vue';

export default [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('./views/Companies'),
    meta: {
      auth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('./views/Users'),
    meta: {
      auth: true
    }
  },
  {
    path: '/users/new',
    name: 'newUser',
    component: () => import('./views/NewUser'),
    meta: {
      auth: true
    }
  },
  {
    path: '/users/edit',
    name: 'editUser',
    component: () => import('./views/NewUser'),
    meta: {
      auth: true
    }
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('./views/Groups'),
    meta: {
      auth: true
    }
  },
  {
    path: '/groups/new',
    name: 'newGroup',
    component: () => import('./views/NewGroup'),
    meta: {
      auth: true
    }
  },
  {
    path: '/groups/edit',
    name: 'editGroup',
    component: () => import('./views/NewGroup'),
    meta: {
      auth: true
    }
  },
  {
    path: '/forms/:id/records',
    name: 'records',
    component: () => import('./views/Records'),
    meta: {
      auth: true
    }
  },
  {
    path: '/forms/:id/:recordId/:tab?',
    name: 'editFormRecord',
    component: () => import('./views/Form'),
    meta: {
      auth: true
    }
  },
  {
    path: '/forms/:id',
    name: 'formRecord',
    component: () => import('./views/Form'),
    meta: {
      auth: true
    }
  },
  {
    path: '/forms',
    name: 'forms',
    component: () => import('./views/Forms'),
    meta: {
      auth: true
    }
  },
  {
    path: '/logs/form/:id',
    name: 'logs',
    component: () => import('./views/Logs'),
    meta: {
      auth: true
    }
  },
  {
    path: '/log/:id',
    name: 'log',
    component: () => import('./views/Log')
  },
  {
    path: '/confirmation/:code',
    name: 'confirmation',
    component: () => import('./views/Confirmation'),
    meta: {
      auth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: home,
    meta: {
      auth: false
    }
  },
  {
    path: '/forgot',
    name: 'forgotPassword',
    component: () => import('./views/ForgotPassword'),
    meta: {
      auth: false
    }
  },
  {
    path: '/reset/:token',
    name: 'resetPassword',
    component: () => import('./views/ResetPassword'),
    meta: {
      auth: false
    }
  },
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      auth: false
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./views/Profile'),
    meta: {
      auth: true
    }
  },
  {
    path: '/custom-actions',
    name: 'customActions',
    component: () => import('./views/CustomActions'),
    meta: {
      auth: true
    }
  },
  {
    path: '/code-lists',
    name: 'codeLists',
    component: () => import('./views/CodeLists'),
    meta: {
      auth: true
    }
  }
];
