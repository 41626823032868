<template>
  <div>
    <div class="tabs is-boxed" :class="{'is-small': small}">
      <ul>
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
