/**
 * This is a global configuration module for the application.
 */
(function () {
  var config = function () {
    var Config = {};
    var options = {
      cookieName: 'user-' + window.location.hostname,
      api: Object.freeze({
        forms: '/api/forms/',
        records: '/api/forms/__formId__/records/',
        importRecords: '/api/forms/__formId__/records/import',
        logs: '/api/logs/form/__logId__',
        log: '/api/logs/__logId__',
        filterRecords: (formId, query) =>
          `/api/forms/${formId}/records/filter/`,
        users: '/api/users/',
        groups: '/api/groups/',
        filterGroups: (id = '') => `/api/companies/${id}/groups`,
        login: '/api/users/login',
        signout: '/api/users/signout',
        confirmation: '/api/users/confirm/',
        reset: '/api/users/reset/',
        companies: '/api/companies/',
        customActions: '/api/custom-action/',
        avatar: userId => `/api/users/${userId}/avatar/`,
        codeLists: '/api/code-list/',
        templates: (id = '') => `/api/templates/${id}`,
        export: (id = '') => `/api/export/${id}`,
        file: '/api/file/',
      })
    };

    Config.install = function (Vue) {
      Vue.prototype.$formsConfig = this;
      Vue.formsConfig = this;
    };

    Config.core = Object.freeze(options);

    return Config;
  }.call();

  window.$formsConfig = config;
}.call());
