var set = function(key, value, expires, path, secure) {
  // Internet Explorer 6-11
  // eslint-disable-next-line
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  if (isIE || isEdge) {
    if (!key) {
      throw new Error('cookie name is not find in first argument');
    } else if (/^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
      throw new Error(
        "cookie key name illegality ,Cannot be set to ['expires','max-age','path','domain','secure']\t",
        'current key name: ' + key
      );
    }
    // support json object
    if (value && value.constructor === Object) {
      value = JSON.stringify(value);
    }
    document.cookie =
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(value) +
      ';expires:' +
      86400 +
      (path ? '; path=' + path : '/') +
      (secure ? '; secure' : '');
  } else {
    $cookies.set(key, value);
  }
};

var remove = function(key, path, domain) {
  // Internet Explorer 6-11
  // eslint-disable-next-line
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  if (isIE || isEdge) {
    if (!key) {
      return false;
    }
    document.cookie = document.cookie =
      encodeURIComponent(key) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '/');
  } else {
    $cookies.remove(key);
  }
};

var handler = {
  set,
  remove
};

export default handler;
