<template>
  <li :class="{ 'is-active': isActive, isDisabled: disabled }">
    <a>
      <slot></slot>
    </a>
  </li>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disabled: this.isDisabled
    };
  },
  created() {
    this.$root.$on('unlockTabs', () => (this.disabled = false));
  }
};
</script>

<style lang="scss" scoped>
li {
  cursor: pointer;
}
.isDisabled {
  opacity: 0.4;
  pointer-events: none;
}
</style>
