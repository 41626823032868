import Vue from 'vue';
import Router from 'vue-router';
import Routes from './routes';
import cookieHandler from './../cookieHandler';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes
});

/**
 * Performs an authentication validation on each route.
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
const auth = function(to, from, next) {
  let user = $cookies.get($formsConfig.core.cookieName);
  user = typeof user === 'object' ? user : null;
  if (to.meta.auth === true && !user) {
    next({
      path: '/login',
      query: {
        from: to.fullPath
      }
    });
  } else if (to.meta.auth === false && user) {
    next({
      path: '/forms'
    });
  } else {
    next();
    cookieHandler.set($formsConfig.core.cookieName, user);
  }
};

/**
 * Configure an authentication check for each path.
 */
router.beforeEach(auth);

export default router;
