export default {
  bind: function(el) {
    el.addEventListener(
      'click',
      () => {
        const currentActiveClassEl = document
          .getElementById('mainFieldsContainer')
          .getElementsByClassName('isActive');
        if (currentActiveClassEl.length > 0) {
          currentActiveClassEl[0].classList.remove('isActive');
        } else {
          const currentActiveClassEl = document.getElementById(
            'formInfoContainer'
          );
          currentActiveClassEl.classList.remove('isActive');
        }
        el.classList.add('isActive');
      },
      false
    );
  }
};
